import React, { useEffect, useState, useRef } from 'react';

import {
    rando
} from './utils';

const matrixGridSize = 10;

// ascii for the ying yang
const yingYangMatrixes = [
    [
        [0, 0, 0, 2, 2, 2, 2, 0, 0, 0],
        [0, 0, 2, 2, 2, 2, 2, 2, 0, 0],
        [0, 2, 2, 2, 2, 3, 2, 2, 3, 0],
        [2, 2, 2, 2, 2, 2, 2, 2, 3, 3],
        [2, 2, 2, 2, 2, 2, 2, 3, 3, 3],
        [2, 2, 2, 3, 3, 3, 3, 3, 3, 3],
        [2, 2, 3, 3, 3, 3, 3, 3, 3, 3],
        [0, 2, 3, 3, 2, 3, 3, 3, 3, 0],
        [0, 0, 3, 3, 3, 3, 3, 3, 0, 0],
        [0, 0, 0, 3, 3, 3, 3, 0, 0, 0]
    ],
    [
        [0, 0, 0, 2, 2, 2, 2, 0, 0, 0],
        [0, 0, 2, 2, 2, 2, 2, 2, 0, 0],
        [0, 2, 2, 2, 2, 2, 2, 2, 2, 0],
        [2, 2, 2, 2, 2, 2, 3, 2, 2, 3],
        [2, 2, 3, 3, 2, 2, 2, 2, 2, 3],
        [2, 3, 3, 3, 3, 3, 2, 2, 3, 3],
        [2, 3, 3, 2, 3, 3, 3, 3, 3, 3],
        [0, 3, 3, 3, 3, 3, 3, 3, 3, 0],
        [0, 0, 3, 3, 3, 3, 3, 3, 0, 0],
        [0, 0, 0, 3, 3, 3, 3, 0, 0, 0]
    ],
    [
        [0, 0, 0, 2, 2, 2, 2, 0, 0, 0],
        [0, 0, 2, 2, 2, 2, 2, 2, 0, 0],
        [0, 3, 3, 3, 2, 2, 2, 2, 2, 0],
        [3, 3, 3, 3, 3, 2, 2, 2, 2, 2],
        [3, 3, 2, 3, 3, 2, 2, 2, 2, 2],
        [3, 3, 3, 3, 3, 2, 2, 3, 2, 2],
        [3, 3, 3, 3, 3, 2, 2, 2, 2, 2],
        [0, 3, 3, 3, 3, 3, 2, 2, 2, 0],
        [0, 0, 3, 3, 3, 3, 3, 3, 0, 0],
        [0, 0, 0, 3, 3, 3, 3, 0, 0, 0]
    ],
    [
        [0, 0, 0, 2, 2, 2, 2, 0, 0, 0],
        [0, 0, 3, 3, 3, 2, 2, 2, 0, 0],
        [0, 3, 3, 2, 3, 3, 2, 2, 2, 0],
        [3, 3, 3, 3, 3, 3, 2, 2, 2, 2],
        [3, 3, 3, 3, 3, 2, 2, 2, 2, 2],
        [3, 3, 3, 3, 3, 2, 2, 2, 2, 2],
        [3, 3, 3, 3, 2, 2, 3, 2, 2, 2],
        [0, 3, 3, 3, 2, 2, 2, 2, 2, 0],
        [0, 0, 3, 3, 3, 2, 2, 2, 0, 0],
        [0, 0, 0, 3, 3, 3, 3, 0, 0, 0]
    ],
    [
        [0, 0, 0, 3, 3, 3, 3, 0, 0, 0],
        [0, 0, 3, 3, 3, 3, 3, 3, 0, 0],
        [0, 3, 3, 3, 3, 2, 3, 3, 2, 0],
        [3, 3, 3, 3, 3, 3, 3, 3, 2, 2],
        [3, 3, 3, 3, 3, 3, 3, 2, 2, 2],
        [3, 3, 3, 2, 2, 2, 2, 2, 2, 2],
        [3, 3, 2, 2, 2, 2, 2, 2, 2, 2],
        [0, 3, 2, 2, 3, 2, 2, 2, 2, 0],
        [0, 0, 2, 2, 2, 2, 2, 2, 0, 0],
        [0, 0, 0, 2, 2, 2, 2, 0, 0, 0]
    ],
    [
        [0, 0, 0, 3, 3, 3, 3, 0, 0, 0],
        [0, 0, 3, 3, 3, 3, 3, 3, 0, 0],
        [0, 3, 3, 3, 3, 3, 3, 3, 3, 0],
        [3, 3, 3, 3, 3, 3, 2, 3, 3, 2],
        [3, 3, 2, 2, 3, 3, 3, 3, 3, 2],
        [3, 2, 2, 2, 2, 2, 3, 3, 2, 2],
        [3, 2, 2, 3, 2, 2, 2, 2, 2, 2],
        [0, 2, 2, 2, 2, 2, 2, 2, 2, 0],
        [0, 0, 2, 2, 2, 2, 2, 2, 0, 0],
        [0, 0, 0, 2, 2, 2, 2, 0, 0, 0]
    ],
    [
        [0, 0, 0, 3, 3, 3, 3, 0, 0, 0],
        [0, 0, 3, 3, 3, 3, 3, 3, 0, 0],
        [0, 2, 2, 2, 3, 3, 3, 3, 3, 0],
        [2, 2, 2, 2, 2, 3, 3, 3, 3, 3],
        [2, 2, 3, 2, 2, 3, 3, 3, 3, 3],
        [2, 2, 2, 2, 2, 3, 3, 2, 3, 3],
        [2, 2, 2, 2, 2, 3, 3, 3, 3, 3],
        [0, 2, 2, 2, 2, 2, 3, 3, 3, 0],
        [0, 0, 2, 2, 2, 2, 2, 2, 0, 0],
        [0, 0, 0, 2, 2, 2, 2, 0, 0, 0]
    ],
    [
        [0, 0, 0, 3, 3, 3, 3, 0, 0, 0],
        [0, 0, 2, 2, 2, 3, 3, 3, 0, 0],
        [0, 2, 2, 2, 2, 2, 3, 3, 3, 0],
        [2, 2, 2, 3, 2, 2, 3, 3, 3, 3],
        [2, 2, 2, 2, 2, 3, 3, 3, 3, 3],
        [2, 2, 2, 2, 2, 3, 3, 3, 3, 3],
        [2, 2, 2, 2, 3, 3, 2, 3, 3, 3],
        [0, 2, 2, 2, 3, 3, 3, 3, 3, 0],
        [0, 0, 2, 2, 2, 3, 3, 3, 0, 0],
        [0, 0, 0, 2, 2, 2, 2, 0, 0, 0]
    ]
];

export default function AsciiBackground(props) {
    const {
        sectionSizes,
        fontSizes,
        stringToAnimate,
        defaultColour,
        secondaryColour,
        maxOpacity,
        matrixSectionBorderOnly,
        matrixSectionBorderWidth,
        matrixSectionMargin,
        yingYangInterval,
        nonCharacters,
        nonCharacterWhitespaceChance,
        characterAnimationInterval,
        characterWaveWidth,
        characterMinOpacity,
        animationInterval,
        animationStartAndEndBuffer,
        characterWaveWhiteSpaceAtEdgeChance,
        characterWaveWhiteSpaceAtEdgeCutoff
    } = props;

    const canvasWrapper = useRef();
    const canvas = useRef();
    const ctx = useRef();

    const yingYangFrame = useRef(0);
    const drawCount = useRef(0);
    const currCharacterIndex = useRef(0);
    const character = useRef(stringToAnimate[0]);
    const activeAnimationLength = useRef(null);
    const activeAnimationProgress = useRef(-animationStartAndEndBuffer);
    const matrixColours = useRef(null);

    const [missingProps, setMissingProps] = useState(false);

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    const handleDraw = progressAnimation => {
        // clear canvas
        ctx.current.clearRect(0, 0, canvas.width, canvas.height);

        if (!progressAnimation) return;

        // set canvas height & width to match screen
        const height = canvasWrapper.current.clientHeight;
        const width = canvasWrapper.current.clientWidth;

        canvas.current.height = height;
        canvas.current.width = width;

        let sectionSizeToUse = returnDynamicValueFromWidth(width, sectionSizes);

        // determine how many squares will fill the screen
        const rawRows = height / sectionSizeToUse;
        const rawColumns = width / sectionSizeToUse;

        // floor those values in order to determine the "desirable" amount of those values
        const desirableRows = Math.floor(rawRows);
        const desirableColumns = Math.floor(rawColumns);

        // calculate the perfect size of the section for bot the rows and the columns
        const potentialSectionHeight = height / desirableRows;
        const potentialSectionWidth = width / desirableColumns;

        // determine the average of those two potential values, this creates the closest value possible imo (i might be wrong)
        let malleableSectionSize = (potentialSectionHeight + potentialSectionWidth) / 2;

        // set active animation values
        activeAnimationLength.current = desirableRows + desirableColumns;

        // loop over rows
        for (let r = 0; r < desirableRows + 1; r++) {
        // loop over columns
            for (let c = 0; c < desirableColumns + 1; c++) {
                // is in the matrix ascii anim
                let isInMatrix = isSectionInMatrix(desirableRows, desirableColumns, [r, c]);

                if (isInMatrix) {
                    let colourToUse = returnMatrixColour(isInMatrix);

                    ctx.current.fillStyle = colourToUse;

                    ctx.current.fillRect(
                        (c * malleableSectionSize) + matrixSectionMargin,
                        (r * malleableSectionSize) + matrixSectionMargin,
                        malleableSectionSize - (matrixSectionMargin * 2),
                        malleableSectionSize - (matrixSectionMargin * 2)
                    );

                    // if borders only is active then we want to draw a rect in the center of the section
                    if (matrixSectionBorderOnly) {
                        ctx.current.clearRect(
                            (c * malleableSectionSize) + matrixSectionMargin + matrixSectionBorderWidth,
                            (r * malleableSectionSize) + matrixSectionMargin + matrixSectionBorderWidth,
                            malleableSectionSize - (matrixSectionMargin * 2) - (matrixSectionBorderWidth * 2),
                            malleableSectionSize - (matrixSectionMargin * 2) - (matrixSectionBorderWidth * 2)
                        );
                    };
                } else {
                    let drawing = (r + c) <= (activeAnimationProgress.current + characterWaveWidth) && (r + c) >= (activeAnimationProgress.current - characterWaveWidth);

                    let colourToUse =
                        drawing ? returnColour(maxOpacity - (Math.abs(activeAnimationProgress.current - (r + c)) / characterWaveWidth), true)
                        : returnColour(characterMinOpacity);

                    ctx.current.fillStyle = colourToUse;

                    // set the font value for the context (doing this at top level doesn't seem to work, idk y)
                    ctx.current.font = `${returnDynamicValueFromWidth(width, fontSizes)}px monospace`;

                    // fetch the metrics for the text so that we can center it within the section
                    let characterToUse = drawing ? character.current : rando(1, 100) <= nonCharacterWhitespaceChance ? '' : nonCharacters[rando(0, nonCharacters.length - 1)];

                    let characterMetrics = ctx.current.measureText(characterToUse);
                    let cW = characterMetrics.width;
                    let cH = characterMetrics.actualBoundingBoxAscent;

                    ctx.current.fillText(characterToUse, (c * malleableSectionSize) + ((malleableSectionSize - cW) / 2), (r * malleableSectionSize) - ((malleableSectionSize - cH ) / 2) + malleableSectionSize);
                };
            };
        };

        // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

        // handle animation progress here
        if (progressAnimation) {
            activeAnimationProgress.current++;
            if (activeAnimationProgress.current > activeAnimationLength.current + animationStartAndEndBuffer) {
                activeAnimationProgress.current = -animationStartAndEndBuffer;
            };
        };

        // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

        // if this is the initial draw then fade in the canvas and add the event listener
        if (!drawCount.current) {
            canvas.current.style.opacity = 1;
            window.addEventListener('resize', () => handleDraw(false));
            drawCount.current++;
        };
    };

    // probs a really hacky way to do this, whatevs
    const returnDynamicValueFromWidth = (width, obj) => {
        if (width > 2500) {
            return obj.ub3r;
        } else if (width <= 480) {
            return obj[480];
        } else if (width <= 768) {
            return obj[768];
        } else if (width <= 1024) {
            return obj[1024];
        } else if (width <= 1200) {
            return obj[1200];
        } else if (width <= 1500) {
            return obj[1500];
        } else {
            return obj[2500];
        };
    };

    const returnColour = (opacity, inWave) => {
        return `rgba(${defaultColour.join(',')}, ${
            // if the colour being returned is one for a character in the wave, and the opacity is lower than the cutoff (at the edge of the wave) then determine if its completely transparent
            inWave && opacity <= characterWaveWhiteSpaceAtEdgeCutoff && rando(1, 100) <= characterWaveWhiteSpaceAtEdgeChance ? 0
            : opacity >= characterMinOpacity ? opacity
            : characterMinOpacity
        })`;
    };

    const returnMatrixColour = matrixValue => {
        return `rgba(${matrixColours.current[matrixValue].join(',')})`;
    };

    const iterateThroughString = () => {
        // change the character value to be that of the next index
        currCharacterIndex.current++;
        if (currCharacterIndex.current >= stringToAnimate.length) {
            currCharacterIndex.current = 0;
        };

        character.current = stringToAnimate[currCharacterIndex.current];
    };

    const isSectionInMatrix = (rows, columns, sectionCoords) => {
        // determine the top and left coords of the matrix grid
        let top = Math.floor((columns / 2) - (matrixGridSize / 2));
        let left = Math.floor((rows / 2) - (matrixGridSize / 2));

        // determine if the section is within those coordinates
        let isInMatrixGrid =
            sectionCoords[0] >= left && sectionCoords[0] <= left + matrixGridSize - 1
            && sectionCoords[1] >= top && sectionCoords[1] <= top + matrixGridSize - 1;

        let isMatrixCharacter = false;

        if (isInMatrixGrid) {
            // if the coordinates of the section in the matrix grid path to a truthy value in the matrix then it is part of the ascii anim
            let coordsInGrid = [matrixGridSize - ((left + matrixGridSize) - sectionCoords[0]), matrixGridSize - ((top + matrixGridSize - sectionCoords[1]))];

            isMatrixCharacter = yingYangMatrixes[yingYangFrame.current][coordsInGrid[0]][coordsInGrid[1]];
        };

        return isMatrixCharacter;
    };

    const animateYingYang = () => {
        yingYangFrame.current++;
        if (yingYangFrame.current > yingYangMatrixes.length - 1) {
            yingYangFrame.current = 0;
        };

        handleDraw(false);
    };

    const handleResize = () => {
        if (drawCount.current > 0) {
            handleDraw(false);
        };
    };

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    useEffect(() => {
        ctx.current = canvas.current.getContext('2d');

        if (!stringToAnimate) {
            setMissingProps('stringToAnimate');
        };

        if (Array.isArray(defaultColour) && Array.isArray(secondaryColour)) {
            matrixColours.current = {
                2: [...defaultColour, maxOpacity],
                3: [...secondaryColour, maxOpacity]
            };
        } else {
            setMissingProps('defaultColour || secondaryColour');
        };

        // all of the intervals that deal with the animation
        let animIntervalRef = setInterval(() => handleDraw(true), animationInterval);
        let yingYangIntervalRef = setInterval(animateYingYang, yingYangInterval);
        let iterateThroughStringIntervalRef = setInterval(iterateThroughString, characterAnimationInterval);

        window.addEventListener('resize', handleResize);

        if (canvasWrapper?.current) canvasWrapper.current.style.opacity = 1;

        return () => {
            clearInterval(animIntervalRef);
            clearInterval(yingYangIntervalRef);
            clearInterval(iterateThroughStringIntervalRef);

            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    if (missingProps) {
        console.log('ascii background missing props', missingProps);
    };

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    return (
        <div
            ref={canvasWrapper}
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                opacity: 0,
                transition: 'opacity 3s',
                zIndex: -1,
                height: '100%',
                width: '100%',
                backgroundColor: 'rgb(0, 0, 30)'
            }}
        >
            <canvas ref={canvas}></canvas>
        </div>
    );
};