import { motion } from 'framer-motion';

import chev from './Content.module.css';

const content = [
    {
        title: 'dl-194',
        item: (
            <div>
                <p>w/ dank collective _as d1337 @ well worn</p>
                <a target="_blank" rel="noreferrer" href="https://vimeo.com/879897770/">https://vimeo.com/879897770/</a>
                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/p/Cyk5_Aso6bh/">https://www.instagram.com/p/Cyk5_Aso6bh/</a>
                <a target="_blank" rel="noreferrer" href="https://www.dankcollective.org/">dank collective</a>
                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/_well_worn/">well worn</a>
            </div>
        )
    },
    {
        title: 'dank vs kühle wampe',
        item: (
            <div>
                <p>w/ dank collective & kühle wampe @ gasleak mountain</p>
                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/p/CvVSH-jI-c7/">https://www.instagram.com/p/CvVSH-jI-c7/</a>
                <a target="_blank" rel="noreferrer" href="https://www.dankcollective.org/">dank collective</a>
                <a target="_blank" rel="noreferrer" href="http://kuhle-wampe.co.uk/">kühle wampe</a>
                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/gasleakmountain/">gasleak mountain</a>
            </div>
        )
    },
    {
        title: 'morphogenic angels arcade',
        item: (
            <div>
                <p>w/ keiken @ helsinki biennial</p>
                <a target="_blank" rel="noreferrer" href="https://www.morphogenicangels.world/">https://www.morphogenicangels.world/</a>
                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/_keiken_/">keiken</a>
                <a target="_blank" rel="noreferrer" href="https://helsinkibiennaali.fi/en/">helsinki biennial</a>
            </div>
        )
    },
    {
        title: 'wisdoms for neknel',
        item: (
            <div>
                <p>w/ keiken & daata</p>
                <a target="_blank" rel="noreferrer" href="https://neknel.world/">https://neknel.world/</a>
                <a target="_blank" rel="noreferrer" href="https://keiken.cloud">keiken</a>
                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/daata.art/">daata</a>
            </div>
        )
    },
    {
        title: 'doozy livestream',
        item: (
            <div>
                <p>w/ dank collective @ doozy magazine launch / strange brew bristol</p>
                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/p/CpDoiDnoFAy/">https://www.instagram.com/p/CpDoiDnoFAy/</a>
                <a target="_blank" rel="noreferrer" href="https://www.dankcollective.org/">dank collective</a>
                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/doozymagazine/">doozy magazine</a>
                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/strange_brew_briz/">strange brew bristol</a>
            </div>
        )
    },
    {
        title: 'pro(tech)t',
        item: (
            <div>
                <p>w/ limbo and hatch & latent space</p>
                <a target="_blank" rel="noreferrer" href="https://protecht.vercel.app/">https://protecht.vercel.app/</a>
                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/limboandhatch/">limbo and hatch</a>
                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/latent___space/">latent space</a>
            </div>
        )
    },
    {
        title: 'wisdoms for love 3.0',
        item: (
            <div>
                <p>w/ keiken @ hek basel</p>
                <a target="_blank" rel="noreferrer" href="https://keiken.cloud">keiken</a>
                <a target="_blank" rel="noreferrer" href="https://www.hek.ch/">hek basel</a>
            </div>
        )
    },
    {
        title: 'i-awhora',
        item: (
            <div>
                <p>w/ awhora & reece owen & george jasper stone @ gay times</p>
                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/awhora/">awhora</a>
                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/reece.owen/">reece owen</a>
                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/georgejasperstone/">george jasper stone</a>
                <a target="_blank" rel="noreferrer" href="https://www.gaytimes.co.uk/">gay times</a>
            </div>
        )
    },
    {
        title: 'nbs.ai.v13.3.7',
        item: (
            <div>
                <p>w/ cultural policy & areyes studio @ feltzine</p>
                <a target="_blank" rel="noreferrer" href="https://posthumanisland.com/">https://posthumanisland.com/</a>
                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/culturalpolicy/">cultural policy</a>
                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/areyes.studio/">areyes studio</a>
                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/feltzine/">felt zine</a>
            </div>
        )
    },
    {
        title: 'villa rosa',
        item: (
            <div>
                <p>curated by ian j williamson</p>
                <a target="_blank" rel="noreferrer" href="https://www.ianjacobwilliamson.com/">ian j williamson</a>
            </div>
        )
    },
    {
        title: 'viral energy game',
        item: (
            <div>
                <p>w/ keiken</p>
                <a target="_blank" rel="noreferrer" href="https://viralenergygame.herokuapp.com/">https://viralenergygame.herokuapp.com/</a>
                <a target="_blank" rel="noreferrer" href="https://keiken.cloud">keiken</a>
            </div>
        )
    },
];

export default function Content() {
    return (
        <motion.div
            id={chev['root']}
            initial={{
                opacity: 0
            }}
            animate={{
                opacity: 1
            }}
            transition={{
                delay: 3,
                duration: 2
            }}
        >
            <div id={chev['content']}>
                <div id={chev['top']}>
                    <h1>obso1337</h1>

                    <p>tobias seymour & lachlan kosaniuk innes</p>

                    <a href="mailto:obso1337.life@gmail.com">obso1337.life@gmail.com</a>
                    <a target="_blank" rel="noreferrer" href="https://www.instagram.com/obso1337.life/">insta</a>
                </div>

                {content.map(thing =>
                    <div className={chev['thing']}>
                        <p>{thing.title}</p>

                        {thing.item}
                    </div>
                )}
            </div>
        </motion.div>
    );
};