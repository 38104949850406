import React,
{
    useEffect,
    useRef
} from 'react';

import {
    useThree,
    useFrame
} from '@react-three/fiber';

import {
    useGLTF,
    Center
} from '@react-three/drei';

import { motion } from "framer-motion-3d";

import * as THREE from 'three';

import Lotus from './Lotus';

const Border = (props) => {

    const { nodes, materials } = useGLTF('/obso_border_2.glb');

    const top = useRef();
    const bottom = useRef();
    // const ring = useRef();

    const { viewport, size } = useThree();

    const material = new THREE.MeshPhysicalMaterial({
        color: 'white',
        clearcoat: 1,
        clearcoatRoughness: 0.1,
        emissive: 'grey',
        emissiveIntensity: 0.15,
        roughness: 0,
        metalness: 1
    });
    
    useFrame((state) => {
        const { viewport, size } = state;
        if (top?.current && bottom?.current) {
            top.current.position.x = viewport.width * -0.044;
            top.current.position.y = viewport.height * 0.024;
            bottom.current.position.x = viewport.width * 0.0372;
            bottom.current.position.y = viewport.height * -0.029;
        };
    });

    return (
        <motion.group
            {...props}
            dispose={null}
            position={[0, 0, -0.5]}
            initial={{scale: 1.5}}
            animate={{scale: 1}}
            transition={{
                delay: 0.5,
                duration: 0.5,
                type: 'spring'
            }}
        >
            <mesh
                ref={top}
                geometry={nodes.border_top.geometry}
                material={material}
                position={[0, 0, 0]}
                rotation={[Math.PI / 2, 0, 0]}
                scale={1}
            />
            <mesh
                ref={bottom}
                geometry={nodes.border_bottom.geometry}
                material={material}
                position={[0, 0, 0]}
                rotation={[Math.PI / 2, 0, 0]}
                scale={1}
            />
            <Lotus/>
        </motion.group>
    );

};

useGLTF.preload('/obso_border.glb');

export default Border;
